import React, { useState, CSSProperties } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby'
import { GiNoodles } from 'react-icons/gi'
import { GrInstagram } from 'react-icons/gr'
import { AiOutlineYoutube } from 'react-icons/ai'
import { FaPaypal, FaEthereum } from 'react-icons/fa'
import { IoLogoVenmo } from 'react-icons/io5'
import { RiFacebookCircleFill } from 'react-icons/ri'

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <SEO title="Chef Tu David Phu" description=" Chef | Film Maker | Consultant" />
      <div style={styles.links}>
        <a target="_blank" style={styles.link} href="https://cheftu.com"><GiNoodles color="#fff" size={32} /></a>
        <a target="_blank" style={styles.link} href="https://www.instagram.com/cheftudavidphu/"><GrInstagram color="#fff" size={32} /></a>
        <a target="_blank" style={styles.link} href="https://www.youtube.com/channel/UCT7h3KX8qXO-sBVOHiI9ZXA?sub_confirmation=1"><AiOutlineYoutube color="#fff" size={32} /></a>
        <a target="_blank" style={styles.link} href="https://www.paypal.me/ChefTuDavidPhu"><FaPaypal color="#fff" size={32} /></a>
        <Link to="/venmo" style={styles.link}><IoLogoVenmo color="#fff" size={32} /></Link>
        <Link to="/eth" style={styles.link}><FaEthereum color="#fff" size={32} /></Link>
        <a target="_blank" style={styles.link} href="https://facebook.com/cheftudavidphu"><RiFacebookCircleFill color="#fff" size={32} /></a>
      </div>
    </Layout>
  )
}

const styles: {
  links: CSSProperties,
  link: CSSProperties
} = {
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    width: '100vw',
    height: '100vh'
  },
  link: {

  }
};

export default IndexPage
